/**
 * Header should be put at the top of every page to describe what that page contains
 */

import { Typography } from "@mui/material";
import { ReactNode } from "react";

interface HeaderProps {
  pageName: string;
  pageDescription: string | ReactNode;
}

export const Header: React.FC<HeaderProps> = ({
  pageName,
  pageDescription,
}) => (
  <div style={{ padding: "32px", paddingBottom: 0 }}>
    <Typography variant="h3">{pageName}</Typography>
    <Typography variant="subtitle1">{pageDescription}</Typography>
  </div>
);

export interface PanelHeaderProps {
  text: string;
}

export const PanelHeader: React.FC<PanelHeaderProps> = ({ text }) => (
  <Typography variant="h5" sx={{ marginBottom: "8px" }}>
    {text}
  </Typography>
);
