/**
 * SearchBar is a text field that is used to perform search.
 */

import { Search } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import styled from "styled-components";

export interface SearchBarProps {
  placeholder: string;

  onChange: (value: string) => void;
}

export const SearchBar: React.FC<SearchBarProps> = (props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const { onChange } = props;

  useEffect(() => {
    onChange(debouncedSearchQuery);
  }, [debouncedSearchQuery, onChange]);

  return (
    <Box sx={{ textAlign: "left" }}>
      <StyledTextBox
        placeholder={props.placeholder}
        value={searchQuery}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: <Search sx={{ marginRight: 1 }} />,
        }}
        sx={{ maxWidth: 600, alignSelf: "flex-start" }}
      />
    </Box>
  );
};

const StyledTextBox = styled(TextField)({
  margin: "0 auto",
  width: "100%",
  backgroundColor: "#fff",
});
