import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        loading: true,
        loggedIn: false,
        username: ''
    },
    reducers: {
        setLoading: (state) => {
            state.loading = true;
        },
        setLoggedIn: (state, action) => {
            state.loading = false;
            state.loggedIn = true;
            state.username = action.payload;
        },
        setLoggedOut: (state) => {
            state.loading = false;
            state.loggedIn = false;
            state.username = '';
        }
    }
});

export const { setLoading, setLoggedIn, setLoggedOut } = authSlice.actions;
export default authSlice.reducer;
