import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { createHistoricalTicketBatch } from "../utils/backend";
import styled from "styled-components";
import { Header } from "../components/common/Header";
import { PageContentNoScroll, Panel } from "../components/common/Layout";

const UploadHistoricalTicketBatchPage: React.FC = () => {
  const [batchName, setBatchName] = useState("");
  const [file, setFile] = useState<File | null>(null);

  const handleBatchNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBatchName(event.target.value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setFile(file);
  };

  const handleSubmit = async () => {
    if (file && batchName) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target?.result as string;
        console.log("got text: " + text);
        try {
          const json = JSON.parse(text);
          await createHistoricalTicketBatch(batchName, json);
          alert("Batch uploaded successfully!");
        } catch (error) {
          alert("Error parsing JSON file");
        }
      };
      reader.readAsText(file);
    } else {
      alert("Please enter a batch name and select a file");
    }
  };

  return (
    <PageContainer>
      <Header
        pageName="Upload New Batch"
        pageDescription="Batches are collections of historical tickets that are pulled from some
        source (ITSM system, JSON dump file, etc.) and that are used to train
        the AI model, and create issue categories.
        <br />
        This page allows uploading tickets from JSON ticket files, and it is
        mainly used for debug purposes."
      />
      <PageContentNoScroll>
        <Panel>
          <TextField
            label="Batch Name"
            value={batchName}
            onChange={handleBatchNameChange}
            margin="normal"
            sx={{ width: "20vw", maxWidth: "600px" }}
          />
          <Typography variant="subtitle1">
            Select a JSON file containing the tickets to
            upload:&nbsp;&nbsp;&nbsp;
          </Typography>
          <input
            type="file"
            onChange={handleFileChange}
            accept="application/json"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ marginTop: 20 }}
          >
            Upload Batch
          </Button>
        </Panel>
      </PageContentNoScroll>
    </PageContainer>
  );
};

const PageContainer = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: "0 24px",
});

export { UploadHistoricalTicketBatchPage };
