/**
 * ListView is a component that pulls data from the backend and displays it in a list. It is suitable to put directly inside a flex paper.
 */

import {
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { ReactNode, useState } from "react";
import { useQuery } from "react-query";

export interface SearchParameters {
  query?: string;
  pageNumber?: number;
}

export interface SearchResults<T> {
  data: T[];
  count: number;
  rowsPerPage: number;
}

/**
 * T is the data type for the list view
 */
export interface ListViewProps<T> {
  searchQuery?: string;

  fetchFunction: (
    searchParameters: SearchParameters
  ) => Promise<SearchResults<T>>;

  columnsToShow: (keyof T)[]; // Property names inside T
  columnNameOverrides?: { [K in keyof T]?: string };

  /**
   * Used to display custom content in some specific columns
   */
  valueRenderOverrides?: {
    [K in keyof T]?: (value: T) => React.ReactNode | string;
  };
}

export const ListView = <T extends {}>(props: ListViewProps<T>) => {
  const [currentPage, setCurrentPage] = useState(1);

  const queryKeyPrefix =
    props.fetchFunction.name + "--" + props.columnsToShow.join("-") + "-";

  const {
    data: fetchResults,
    isLoading,
    isError,
  } = useQuery(
    [
      queryKeyPrefix + "pageNumber",
      currentPage,
      queryKeyPrefix + "searchQuery",
      props.searchQuery,
    ],
    () => {
      return props.fetchFunction({
        pageNumber: currentPage,
        query: props.searchQuery,
      });
    }
  );

  if (isLoading) {
    return (
      <TableRootComponent>
        <LoadingSkeletonComponent />
      </TableRootComponent>
    );
  }

  if (isError) {
    return <TableRootComponent>Error!!!</TableRootComponent>; // TODO: Show which error it is
  }

  if (!fetchResults) {
    return <TableRootComponent>Empty</TableRootComponent>; // TODO: Display this better
  }

  const pagesCount = Math.ceil(
    (fetchResults.count || 0) / fetchResults.rowsPerPage
  );
  const paginationEnabled = pagesCount > 1;

  // Giving default empty values for the columnNameOverrides and valueRenderOverrides parameters in
  // case they were not provided
  const columnNameOverrides =
    props.columnNameOverrides || ({} as { [K in keyof T]?: string });
  const valueRenderOverrides =
    props.valueRenderOverrides ||
    ({} as {
      [K in keyof T]?: (value: T) => React.ReactNode | string;
    });

  const columnHeaders = props.columnsToShow.map((column) => {
    const columnName = columnNameOverrides[column] || column.toString();
    return <TableCell key={columnName.toString()}>{columnName}</TableCell>;
  });

  const rows = fetchResults.data.map((row, index) => {
    const cells = props.columnsToShow.map((column) => {
      const value = row[column];
      const renderOverride = valueRenderOverrides[column];
      const cellContent = renderOverride
        ? renderOverride(row)
        : ((value as any) || "").toString();
      return <TableCell key={column.toString()}>{cellContent}</TableCell>;
    });

    return <TableRow key={index}>{cells}</TableRow>;
  });

  return (
    <Box
      sx={{ flex: 1, display: "flex", flexDirection: "column", minHeight: 0 }}
    >
      <TableRootComponent>
        <TableHead>{columnHeaders}</TableHead>
        <TableBody>{rows}</TableBody>
      </TableRootComponent>

      {paginationEnabled && (
        <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
          <Pagination
            count={pagesCount}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
          />
        </Box>
      )}
    </Box>
  );
};

interface TableRootComponentProps {
  children: ReactNode;
}

const TableRootComponent: React.FC<TableRootComponentProps> = ({
  children,
}) => (
  <TableContainer sx={{ flex: 1 }}>
    <Table stickyHeader>{children}</Table>
  </TableContainer>
);

const LoadingSkeletonComponent: React.FC = () => (
  <TableBody>
    {Array.from(Array(5).keys()).map((index) => (
      <TableRow key={index}>
        <TableCell>
          <Skeleton width={200} />
        </TableCell>
        <TableCell>
          <Skeleton width={200} />
        </TableCell>
        <TableCell>
          <Skeleton width={200} />
        </TableCell>
        <TableCell>
          <Skeleton width={200} />
        </TableCell>
      </TableRow>
    ))}
  </TableBody>
);
