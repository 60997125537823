import { useState, useEffect, useCallback } from "react";
import { TicketPageDisplay, TicketData, Comment } from "./TicketPageDisplay";
import { addCommentToTicket, fetchTicketData } from "../../utils/backend";

const TicketPageLogic: React.FC<{ ticketId: number }> = ({ ticketId }) => {

  const [data, setData] = useState<TicketData>({
    title: "Loading",
    description: "",
    status: "",
    issueCategory: 0,
    comments: []
  });

  const onAddComment = (commentText: string) => {
    console.log("Adding comment to issue: ", ticketId, commentText);
    addCommentToTicket(ticketId, commentText);
  };


  // Function to query backend
  const queryBackend = useCallback(async () => {
    try {
      const response = await fetchTicketData(ticketId); // Replace with your actual backend query function
      // console.log("Got response: ", response);
      const transformedData: TicketData = {
        title: response.title,
        description: response.body,
        status: response.status,
        issueCategory: response.issue_category,
        comments: response.activities.map((activity: any) => {
          let commenter = "";
          if (activity.activity_type === "SYSTEM_QUESTION") {
            commenter = "System";
          } else {
            commenter = "User";
          }
          const comment: Comment = {
            commenter: commenter,
            content: activity.content,
          };
          return comment;
        }),
      }



      setData(transformedData);
      // setError(rerror);
      // setIsLoading(risLoading);
    } catch (error) {
      console.error('Error querying backend:', error);
    }
  }, [setData, ticketId]);

  useEffect(() => {
    const interval = setInterval(() => {
      queryBackend();
    }, 500);

    // Cleanup
    return () => clearInterval(interval);
  }, [queryBackend]); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  return (
    <TicketPageDisplay data={data} onAddComment={onAddComment} />
  );
};

interface TicketPageProps {
  ticketId: number;
}

const TicketPage: React.FC<TicketPageProps> = ({ ticketId }) => {
  return <TicketPageLogic ticketId={ticketId || 0} />;
};

export { TicketPage };
