import React from "react";
import {
  TextField,
  List,
  ListItem,
  IconButton,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { red } from "@mui/material/colors";

interface TriggerListProps {
  triggers: string[];
  onTriggerChange: (index: number, newValue: string) => void;
  onTriggerRemove: (index: number) => void;
}

const TriggerList: React.FC<TriggerListProps> = ({
  triggers,
  onTriggerChange,
  onTriggerRemove,
}) => (
  <List>
    {triggers.map((trigger, index) => (
      <ListItem key={index} divider style={{ paddingLeft: 0, paddingRight: 0 }}>
        <TextField
          fullWidth
          variant="outlined"
          value={trigger}
          onChange={(event) => onTriggerChange(index, event.target.value)}
          margin="dense"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="delete"
                  onClick={() => onTriggerRemove(index)}
                  sx={{
                    "&:hover": { color: red[300].toString() },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </ListItem>
    ))}
  </List>
);

export { TriggerList };
