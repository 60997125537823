/**
 * Skeleton component is the the main component that gives the application its "shape". It contains the top
 * bar, the side bar, any global modals and dialogues, and the main content area.
 *
 * The children provided to this component are rendered in the main content area.
 */

import { useSelector } from "react-redux";
import logo from "../../assets/images/logo_greenblack.png";
import { Spacer } from "../Spacer";
import { FullPageContainer } from "./FullPageContainer";
import { SidebarContent } from "./SidebarContent";
import { styled } from "@mui/system";
import { IRootState } from "../../redux/store";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { LogoutSharp, PersonSharp } from "@mui/icons-material";
import { httpClient } from "../../utils/backend";

interface SkeletonProps {
  children: React.ReactNode;
}

const Skeleton: React.FC<SkeletonProps> = ({ children }) => {
  return (
    <FullPageContainer>
      <Sidebar>
        <AppBarLogo src={logo} alt="logo" />
        {/* TODO: Add search bar */}
        <SidebarContent />
        <Spacer full />
        <LogoutPanel />
      </Sidebar>

      <MainArea>{children}</MainArea>
    </FullPageContainer>
  );
};

const LogoutPanel = () => {
  const { loggedIn, username } = useSelector((state: IRootState) => state.auth);

  const handleLogout = () => {
    httpClient.logout();
    window.location.reload();
  };

  return (
    <>
      {loggedIn && (
        <>
          <ListItem sx={{ padding: 0 }}>
            <ListItemButton>
              <ListItemIcon sx={{ minWidth: 0, marginRight: "4px" }}>
                <PersonSharp />
              </ListItemIcon>
              <ListItemText primary={username} />
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ padding: 0 }}>
            <ListItemButton onClick={handleLogout}>
              <ListItemIcon sx={{ minWidth: 0, marginRight: "4px" }}>
                <LogoutSharp />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </>
      )}
    </>
  );
};

const Sidebar = styled("div")(({ theme }) => ({
  width: 250,
  marginTop: 0,
  padding: theme.spacing(1),

  backgroundColor: theme.palette.background.paper,
  borderLeft: `6px solid ${theme.palette.primary.main}`,

  display: "flex",
  flexDirection: "column",
}));

const AppBarLogo = styled("img")(({ theme }) => ({
  margin: theme.spacing(3),
  marginTop: theme.spacing(1),
}));

const MainArea = styled("div")(({ theme }) => ({
  flex: 1,
  display: "flex",
}));

export { Skeleton };
