import React, { useState } from "react";
import { Container, Typography, TextField, Button } from "@mui/material";
import { Box } from "@mui/system";
import { createTicket } from "../utils/backend";

const IssuePage: React.FC = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Handle the submit action (e.g. make an API call)
    console.log("Title:", title);
    console.log("Description:", description);

    createTicket({ title, description });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 8,
          p: 3, // Padding
          borderRadius: "10px", // Rounded corners
          boxShadow: 3, // Shadow for a little depth
          backgroundColor: "background.default", // <-- This will make the box follow the background.paper color in your theme
        }}
      >
        <Typography component="h1" variant="h5">
          Open a New Issue
        </Typography>
        <Box component="div" sx={{ mt: 3, width: "100%" }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="title"
            label="Issue Title"
            name="title"
            sx={{ backgroundColor: "background.paper" }}
            autoFocus
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="description"
            label="Description"
            sx={{ backgroundColor: "background.paper" }}
            id="description"
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default IssuePage;
