import React from "react";
import { useParams } from "react-router";
import {
  Horizontal,
  PageContainer,
  PageContentScroll,
  Panel,
} from "../components/common/Layout";
import { Header, PanelHeader } from "../components/common/Header";
import { fetchBatchProcess, fetchBatchProcessResult, BatchProcessResult, BatchProcessIssueCategory, migrateBatchProcessResultCategories } from "../utils/backend";
import { useQuery } from "react-query";
import { KeyValuePair } from "../components/common/KeyValuePair";
import { LinearProgress } from "@mui/material";
import { Box, Button } from "@mui/material";
import {
  ListView,
  SearchResults,
} from "../components/common/ListView";


export const BatchProcessPage: React.FC = () => {
  const { process_id: processIdParam } = useParams();

  const processId = processIdParam ? parseInt(processIdParam) : 0;

  const {
    data: process,
    isLoading,
    isError,
  } = useQuery(
    ["processToFetch", processId],
    () => fetchBatchProcess(processId),
    {
      refetchInterval: 3000,
    }
  );


  const resultId = process?.result_id;
  const {
    data: processResult,
  } = useQuery<BatchProcessResult>(
    ["processResult", resultId],
    () => fetchBatchProcessResult(resultId as number),
    {
      enabled: typeof resultId === 'number'
    }
  );

  if (processId === 0) {
    return <>batch_id not found in URL</>;
  }

  if (isError) {
    return <>Error...</>; // TODO: Show something better here
  }

  if (isLoading || !process) {
    return <>Loading...</>; // TODO: Show something better here
  }

  return (
    <PageContainer>
      <Header
        pageName={`Process ${process.id} for batch ${process.batch}`}
        pageDescription="TODO: Explain what a process is"
      />
      <PageContentScroll>
        <Horizontal>
          <Panel height="mid">
            <PanelHeader text="Process Details" />
            <KeyValuePair firstElement title="ID" value={process.id} />
            <KeyValuePair title="Batch ID" value={process.batch} />
            {/* TODO: Link back to the batch page */}
            <KeyValuePair title="Created at" value={process.created_at} />
            <KeyValuePair title="Updated at" value={process.updated_at} />
            <KeyValuePair title="Result" value={process.result || "N/A"} />
          </Panel>
          <Panel height="mid">
            <PanelHeader text="Process Status" />
            <KeyValuePair
              title="Last keepalive"
              value={new Date(process.last_keepalive).toLocaleString()}
            />
            <KeyValuePair title="Status" value={process.status} />
            <KeyValuePair
              title="Progress"
              value={
                <LinearProgress
                  value={process.progress * 100}
                  variant="determinate"
                />
              }
            />
          </Panel>
        </Horizontal>
        <Panel>
          {processResult && (
            <>
              <ListView
                fetchFunction={() => fetchResultIssueCategories(processResult)}
                columnsToShow={["name", "description", "count"]}
              />
              <Box style={{ alignSelf: 'center', marginTop: 15 }}>
                <Button variant="contained" onClick={() => migrateBatchProcessResultCategories(process.result_id)}>
                  UPLOAD
                </Button>
              </Box>
            </>
          )
          }

        </Panel>
      </PageContentScroll>
    </PageContainer>
  );
};

const fetchResultIssueCategories = (processResult: BatchProcessResult): Promise<SearchResults<BatchProcessIssueCategory>> => {
  return new Promise(resolve => {
    resolve({
      data: processResult?.categories ?? [],
      count: processResult?.categories?.length ?? 0,
      rowsPerPage: 10,
    });
  });
}
