import React, { useEffect, useState } from "react";
import {
  Paper,
  List,
  ListItem,
  IconButton,
  Box,
  Button,
  Tooltip,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/system";
import RuleAutocomplete from "./RuleAutocomplete";
import { red } from "@mui/material/colors";

// TODO: This struct won't work well with the backend (need IDs for each). For now it's good. For later, we'll need to refactor this a bit
interface Rule {
  diagnosis: string;
  troubleshooter: string;
}

interface RulesListProps {
  rules: Rule[];
  onRuleChange: (
    index: number,
    field: "diagnosis" | "troubleshooter",
    newValue: string
  ) => void;
  onRuleRemove: (index: number) => void;
  fetchDiagnosisAutoCompleteOptions: (input: string) => Promise<string[]>;
  fetchTroubleshooterAutoCompleteOptions: (input: string) => Promise<string[]>;
}

const RulesList: React.FC<RulesListProps> = ({
  rules,
  onRuleChange,
  onRuleRemove,
  fetchDiagnosisAutoCompleteOptions,
  fetchTroubleshooterAutoCompleteOptions,
}) => {
  const [localRules, setLocalRules] = useState<Rule[]>(rules);

  useEffect(() => {
    setLocalRules(rules);
  }, [rules]);

  const handleRuleChange = (
    index: number,
    field: "diagnosis" | "troubleshooter",
    newValue: string
  ) => {
    const updatedRules = [...localRules];
    updatedRules[index][field] = newValue;
    setLocalRules(updatedRules);
    onRuleChange(index, field, newValue);
  };

  return (
    <ListStyled>
      {localRules.map((rule, index) => (
        <ListItemStyled key={index} divider>
          <PaperStyled>
            <BoxStyled>
              <RuleAutocomplete
                label="When:"
                value={rule.diagnosis}
                onChange={(newValue) =>
                  handleRuleChange(index, "diagnosis", newValue)
                }
                fetchOptions={fetchDiagnosisAutoCompleteOptions}
              />
              <EditButton disabled={!rule.diagnosis} type="diagnosis" />

              <ArrowForwardIosIcon />
              <RuleAutocomplete
                label="Then Do:"
                value={rule.troubleshooter}
                onChange={(newValue) =>
                  handleRuleChange(index, "troubleshooter", newValue)
                }
                fetchOptions={fetchTroubleshooterAutoCompleteOptions}
              />
              <EditButton
                disabled={!rule.troubleshooter}
                type="troubleshooter"
              />

              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => onRuleRemove(index)}
                sx={{
                  "&:hover": {
                    color: red[300].toString(),
                  },
                }}
              >
                <DeleteIcon />
              </IconButton>
            </BoxStyled>
          </PaperStyled>
        </ListItemStyled>
      ))}
    </ListStyled>
  );
};

interface EditButtonProps {
  disabled: boolean;
  type: "diagnosis" | "troubleshooter";
}

const EditButton: React.FC<EditButtonProps> = ({ disabled, type }) => (
  <Tooltip
    title={`This edits the ${type} which is currently selected`}
    enterDelay={700}
  >
    <span>
      {" "}
      {/* Wrap the button in a span to allow tooltip on disabled elements */}
      <Button variant="text" disabled={disabled}>
        Edit
      </Button>
    </span>
  </Tooltip>
);

const ListStyled = styled(List)({
  width: "100%",
  backgroundColor: "background.paper",
});

const ListItemStyled = styled(ListItem)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  paddingLeft: 0,
  paddingRight: 0,
  paddingBottom: 16,
  paddingTop: 16,
});

const PaperStyled = styled(Paper)({
  padding: 16,
  width: "100%",
});

const BoxStyled = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 2,
});

export { RulesList };
