import { createTheme } from "@mui/material/styles";

// const oldTheme = createTheme({
//   palette: {
//     mode: "dark",
//     primary: {
//       main: "#27DDFE",
//     },
//     secondary: {
//       main: "#4AFDAD",
//     },
//     background: {
//       default: "#0e244a",
//       paper: "#081532",
//     },
//     text: {
//       primary: "#FFFFFF",
//       secondary: "#94a4bd",
//     },
//   },
//   components: {
//     MuiTypography: {
//       styleOverrides: {
//         root: ({ theme }) => ({
//           color: theme.palette.text.primary,
//           fontFamily: '"Gilroy","Helvetica","Arial",sans-serif'
//         })
//       }
//     },
//     MuiListItemButton: {
//       styleOverrides: {
//         root: {
//           "&:hover": {
//             backgroundColor: "#152d57",
//           },
//           "&.Mui-selected": {
//             backgroundColor: "#223b68",
//             "&:hover": {
//               backgroundColor: "#152d57",
//             }
//           }
//         },
//       }
//     },
//     MuiPaper: {
//       styleOverrides: {
//         root: {
//           backgroundImage: "linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0))", // MUI is pretty annoying. WTF was that 0.05 that we were given by default?
//         }
//       }
//     }
//   }
// });

const theme = createTheme({
  palette: {
    background: {
      default: "#f2f2f2",
      paper: "#FFFFFF",
    },
    primary: {
      main: "#12d17d",
    },
    secondary: {
      main: "#0a1730",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          fontFamily: '"Gilroy","Helvetica","Arial",sans-serif'
        })
      }
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: "#2c3e50",
        }
      }
    },
  }
});

export { theme };
