import { ReactNode } from "react";

interface KeyValuePairParams {
  title: string | ReactNode;
  value: string | ReactNode;

  firstElement?: boolean;
}

export const KeyValuePair: React.FC<KeyValuePairParams> = ({
  title,
  value,
  firstElement,
}) => {
  const BORDER = "1px solid #ddd";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        paddingTop: "12px",
        paddingBottom: "12px",

        borderTop: firstElement ? BORDER : "none",
        borderBottom: BORDER,
      }}
    >
      <div style={{ flex: 1, fontWeight: "bold" }}>{title}</div>
      <div style={{ flex: 1, margin: "auto" }}>{value}</div>
    </div>
  );
};
