import { styled } from "@mui/system";
import React from "react";

import logo from "../assets/images/logo_greenwhite.png";
import { Spacer } from "../components/Spacer";
import { Button, Typography } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";

const SplashScreen: React.FC = () => {
  return (
    <FullPageContainer>
      <MainArea>
        <Logo />
        <Spacer height={50} />
        <Typography variant="h5" style={{ color: "white" }}>
          We're happy you're here! Let's get started.
        </Typography>
        <Spacer height={50} />
        <Button
          variant="outlined"
          color="primary"
          size="large"
          endIcon={<ChevronRight />}
        >
          Let's go
        </Button>
      </MainArea>
    </FullPageContainer>
  );
};

const FullPageContainer = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,

  backgroundColor: theme.palette.secondary.main,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const MainArea = styled("div")(({ theme }) => ({
  textAlign: "center",
}));

const Logo = () => (
  <img src={logo} alt={logo} style={{ width: 600, maxWidth: "30vw" }} />
);

export { SplashScreen };
