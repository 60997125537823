import { Button, styled } from "@mui/material";
import { TriggerList } from "./TriggerList";
import { useState } from "react";
import { AddSharp, DeleteForever, SaveSharp } from "@mui/icons-material";
import { Spacer } from "../../../components/Spacer";

const TriggersPanel: React.FC = () => {
  // TODO: Query this from backend

  const [triggers, setTriggers] = useState<string[]>([
    "Hey IT, I've been trying to print some documents, but I can't seem to connect to the network printer. Can you help?",
    "I'm having issues printing my files. It seems like there's a connection problem with our network printer.",
    // ... add other trigger texts here
  ]);

  const handleRemoveClick = (index: number) => {
    const newTriggers = [...triggers];
    newTriggers.splice(index, 1);
    setTriggers(newTriggers);
  };

  // const handleAddNewClick = () => {
  //   setTriggers([...triggers, ""]);
  // };

  const handleTriggerChange = (index: number, newValue: string) => {
    const newTriggers = [...triggers];
    newTriggers[index] = newValue;
    setTriggers(newTriggers);
  };

  return (
    <>
      <TriggerList
        triggers={triggers}
        onTriggerChange={handleTriggerChange}
        onTriggerRemove={handleRemoveClick}
      />
      <TriggersButtonsPanel />
    </>
  );
};

const TriggersButtonsPanel: React.FC = () => (
  <ButtonsPanel>
    <PrimaryButton variant="outlined" color="secondary" endIcon={<AddSharp />}>
      Add Rule
    </PrimaryButton>
    <Spacer full />

    <PrimaryButton
      variant="outlined"
      color="secondary"
      endIcon={<DeleteForever />}
    >
      Discard Changes
    </PrimaryButton>
    <PrimaryButton variant="contained" endIcon={<SaveSharp />}>
      Save
    </PrimaryButton>
  </ButtonsPanel>
);

const ButtonsPanel = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(1),
}));

const PrimaryButton = styled(Button)({
  marginTop: 16,
  alignSelf: "flex-start",
  color: "primary",
});

export { TriggersPanel };
