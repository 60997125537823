import React from "react";
import { Batch, fetchBatches } from "../utils/backend";
import {
  ListView,
  SearchParameters,
  SearchResults,
} from "../components/common/ListView";
import { Header } from "../components/common/Header";
import {
  PageContainer,
  PageContentNoScroll,
  Panel,
} from "../components/common/Layout";
import { Link } from "react-router-dom";

const BatchesListPage: React.FC = () => {
  const renderBatchLink = (batch: Batch) => {
    return (
      <Link to={`/batches/${batch.id}`} style={{ color: "inherit" }}>
        {batch.name}
      </Link>
    );
  };

  return (
    <PageContainer>
      <Header
        pageName="Batches"
        pageDescription="List of historical ticket batches collected from this organization."
      />
      <PageContentNoScroll>
        <Panel>
          <ListView
            fetchFunction={fetchData}
            columnsToShow={["id", "name", "source", "created_at"]}
            columnNameOverrides={{
              id: "Batch ID",
              name: "Name",
              source: "Source",
              created_at: "Created At",
            }}
            valueRenderOverrides={{
              source: renderBatchSource,
              name: renderBatchLink,
            }}
          />
        </Panel>
      </PageContentNoScroll>
    </PageContainer>
  );
};

const renderBatchSource = (batch: Batch): string => {
  return (
    {
      JU: "JSON File",
      json_uploaded: "JSON File",
    }[batch.source] || "Unknown"
  );
};

const fetchData = async (
  params: SearchParameters
): Promise<SearchResults<Batch>> => {
  const data = await fetchBatches(params.pageNumber);

  return {
    data: data.results,
    count: data.count,
    rowsPerPage: 10,
  };
};

export { BatchesListPage };
