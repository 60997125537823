import React, { useState, useEffect } from "react";
import { Container, Typography, Box, Button, IconButton, SvgIcon } from "@mui/material";

import ScrollBox from "../components/ScrollBox";
import { TicketPage } from "../pages/TicketPage";

import { fetchAllTickets } from "../utils/backend";

type Ticket = {
    id: number;
    title: string;
    description: string;
    status: string;
};


const IssueListView: React.FC = () => {
    const [tickets, setTickets] = useState<Ticket[]>([]);
    const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);


    useEffect(() => {
        const loadTickets = async () => {
            try {
                // Assuming you have an endpoint to fetch all tickets
                const response = await fetchAllTickets();
                setTickets(response);
            } catch (error) {
                console.error("Failed to fetch tickets", error);
            }
        };

        loadTickets();
    }, []);

    return (
        <Container component="main" sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '1200px',
            // backgroundColor: 'red'
        }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    width: '100%',
                    height: '90%',
                    // backgroundColor: 'green'
                }}
            >
                <Box sx={{
                    transition: "all 0.5s", // smooth transition for animations
                    width: (selectedTicket ? "30%" : "100%"),
                    backgroundColor: "background.default",

                    padding: 2,
                    boxShadow: 3,
                    borderRadius: "10px",
                    height: '100%',
                }}>
                    <Typography component="h1" variant="h5">
                        Previous Issues
                    </Typography>
                    <ScrollBox sx={{ maxHeight: '95%' }}>
                        {tickets.map((ticket) => (
                            <Button
                                sx={{ backgroundColor: 'background.paper', marginBottom: '5px' }}
                                key={ticket.id}
                                onClick={() => setSelectedTicket(ticket)}
                                fullWidth
                                style={{ justifyContent: "space-between", textTransform: "none" }}
                            >
                                <span>{ticket.title}</span> <span>{ticket.status}</span>
                            </Button>
                        ))}
                    </ScrollBox>
                </Box>

                {selectedTicket && (
                    <Box
                        sx={{
                            transition: "all 0.5s", // smooth transition for animations
                            width: (selectedTicket ? "65%" : 0),

                            backgroundColor: "background.default",
                            padding: 2,
                            boxShadow: 3,
                            borderRadius: "10px",
                            height: '100%',
                            position: 'relative',  // to position the close button
                            overflow: 'hidden',
                        }}
                    >
                        {/* Close Icon */}
                        <IconButton
                            sx={{
                                position: 'absolute',  // make the icon stay in a fixed position
                                top: 2,  // adjust these values as you see fit
                                right: 2,
                                padding: '5px',  // make the button smaller
                                '&:hover': {
                                    backgroundColor: 'transparent'
                                }
                            }}
                            onClick={() => setSelectedTicket(null)}
                        >
                            <SvgIcon sx={{ stroke: 'white' }}>
                                <path d="M6 6L18 18M18 6L6 18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </SvgIcon>

                        </IconButton>
                        <TicketPage ticketId={selectedTicket.id} />
                    </Box>
                )}
            </Box>
        </Container>
    );
};

export default IssueListView;
