import React, { useState } from "react";
import { styled } from "@mui/system";
import { Button, Typography, TextField } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";

import logo from "../assets/images/logo_greenwhite.png";
import { Spacer } from "../components/Spacer";
import { httpClient } from "../utils/backend";

const LoginScreen: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    httpClient
      .login(username, password)
      .then((res) => {
        window.location.href = "/";
      })
      .catch((err) => {
        alert("Login failed: " + err.message); // TODO: Better error message here
      });
  };

  return (
    <FullPageContainer>
      <MainArea>
        <Logo />
        <Spacer height={50} />
        <Typography variant="h5" style={{ color: "white" }}>
          Welcome Back! Please login to continue.
        </Typography>
        <Spacer height={30} />
        <StyledTextField
          label="Username"
          variant="outlined"
          color="primary"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Spacer height={20} />
        <StyledTextField
          label="Password"
          variant="outlined"
          color="primary"
          fullWidth
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Spacer height={30} />
        <Button
          variant="outlined"
          color="primary"
          size="large"
          endIcon={<ChevronRight />}
          onClick={handleLogin}
        >
          Login
        </Button>
      </MainArea>
    </FullPageContainer>
  );
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& input": {
    color: "white",
  },
  "& label": {
    color: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
}));

const FullPageContainer = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,

  backgroundColor: theme.palette.secondary.main,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const MainArea = styled("div")(({ theme }) => ({
  textAlign: "center",
}));

const Logo = () => (
  <img src={logo} alt="logo" style={{ width: 600, maxWidth: "30vw" }} />
);

export { LoginScreen };
