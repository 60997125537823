import axios, { AxiosInstance } from "axios";


function getApiEndpoint() {
  // Get the current hostname
  const hostname = window.location.hostname;

  if (hostname === 'app.tevet.ai') {
    return 'https://api.prod.tevet.online/api';
  }
  else if (hostname.endsWith('.tevet.online')) {
    return `https://api.${hostname}/api`;
  } else {
    return 'http://localhost:8000/api';
  }
}

// We prefix with /api since the frontend isn't accessible to the internal/ endpoints anyway
const BACKEND_URL = getApiEndpoint();

class AuthHTTPClient {
  private axiosInstance: AxiosInstance = this.initAxiosInstance();

  private initAxiosInstance(): AxiosInstance {
    const token = this.loadAuthToken();
    const headers = token ? { Authorization: `Token ${token}` } : {};

    let axiosInstance = axios.create({
      baseURL: BACKEND_URL,
      headers,
    });

    // Interceptor to handle automatic logout on 401 responses
    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.removeAuthToken();
        }
        return Promise.reject(error);
      }
    );

    return axiosInstance;
  }

  private loadAuthToken() {
    return localStorage.getItem("authToken") || "";
  }

  private saveAuthToken(token: string) {
    localStorage.setItem("authToken", token);
    this.axiosInstance = this.initAxiosInstance();
  }

  private removeAuthToken() {
    localStorage.removeItem("authToken");
    this.axiosInstance = this.initAxiosInstance();
  }

  async checkLoggedIn() {
    try {
      const res = await this.axiosInstance.get("/users/me/");
      const { username } = res.data;
      return username;
    } catch (error) {
      // this.removeAuthToken();
      return false;
    }
  }

  async login(username: string, password: string) {
    const response = await this.axiosInstance.post("/users/login/", {
      username,
      password,
    });

    this.saveAuthToken(response.data.token);
  }

  async logout() {
    this.removeAuthToken();
  }

  async get(url: string, config?: any) {
    return await this.axiosInstance.get(url, config);
  }

  async post(url: string, data: any) {
    return await this.axiosInstance.post(url, data);
  }

  async put(url: string, data: any) {
    return await this.axiosInstance.put(url, data);
  }

  async delete(url: string) {
    return await this.axiosInstance.delete(url);
  }

  async patch(url: string, data: any) {
    return await this.axiosInstance.patch(url, data);
  }
}

export const httpClient = new AuthHTTPClient();

(window as any).httpClient = httpClient;

export const fetchAllTickets = async () => {
  const response = await httpClient.get("/tickets/");
  return response.data.results;
};

export const fetchTicketData = async (ticketId: number) => {
  const response = await httpClient.get(`/tickets/${ticketId}/`);
  console.log("FetchTicketDat: ", response, "data: ", response.data);
  return response.data;
};

export const fetchIssueCategoryData = async (id: number) => {
  const response = await httpClient.get(`/issue_categories/${id}/rules`);
  return response.data;
};

interface TicketData {
  title: string;
  description: string;
}

export const createTicket = async (ticketData: TicketData) => {
  alert("post");
  const response = await httpClient.post(`/tickets/new/`, {
    title: ticketData.title,
    body: ticketData.description,
    status: "OPEN",
    integration_name: "none",
    integration_ticket_id: 0,
  });
  return response.data;
};

export const addCommentToTicket = async (ticketId: number, comment: string) => {
  const response = await httpClient.post(`/tickets/${ticketId}/add-comment/`, {
    comment: comment,
  });
  return response.data;
};

export interface IssueCategory {
  label: string;
  description: string;
  count: number;
}

export interface SearchIssueCategoriesResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: IssueCategory[];
}

export const searchIssueCategories = async (
  query: string,
  page: number = 1
): Promise<SearchIssueCategoriesResponse> => {
  const response = await httpClient.get(`/issue_categories/search/`, {
    params: { q: query, page: page },
  });
  console.log("search issue categories: ", response.data)
  return response.data;
};

export const createHistoricalTicketBatch = async (
  batchName: string,
  tickets: any[]
) => {
  const response = await httpClient.post(`/historical/upload/`, {
    name: batchName,
    tickets: tickets,
  });
  return response.data;
};

export interface Batch {
  id: number;
  name: string;
  source: string;
  created_at: string;
  updated_at: string;
}

export interface BatchDetails extends Batch {
  tickets_count: number;
}

export interface BatchesResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Batch[];
}

export const fetchBatches = async (
  page: number = 1
): Promise<BatchesResponse> => {
  const response = await httpClient.get(`/historical/batches/?page=${page}`);
  return response.data;
};

export const fetchBatch = async (batchId: number): Promise<BatchDetails> => {
  const response = await httpClient.get(`/historical/batches/${batchId}/`);
  return response.data as BatchDetails;
};

export interface BatchProcessDetails {
  id: number;
  status: string;
  created_at: string;
  updated_at: string;
  last_status_change: string;
  max_retries: number;
  start_attempts: number;
  last_keepalive: string;
  progress: number;
  result: string;
  result_id: number;
  task_type: string;

  batch: number;
}

export const fetchBatchProcess = async (processId: number): Promise<BatchProcessDetails> => {
  const response = await httpClient.get(`/historical/processes/${processId}/`);
  console.log("Batch Process data: ", response.data);
  return response.data as BatchProcessDetails;
}




export interface BatchProcessIssueCategory {
  id: number;
  name: string;
  description: string;
  count: number;
}


export interface BatchProcessResult {
  categories: BatchProcessIssueCategory[];
  id: number;
}



export const fetchBatchProcessResult = async (resultId: number): Promise<BatchProcessResult> => {
  const response = await httpClient.get(`/historical/results/${resultId}/`);
  console.log("Batch Process Result data: ", response.data);
  return { categories: response.data.categories, id: response.data.id };
}

export const migrateBatchProcessResultCategories = async (resultId: number) => {
  const response = await httpClient.post(`/historical/results/${resultId}/migrate/`, {});
  return response.status;
}


export interface MinimalTicket {
  title: string;
  original_ticket_id: string;
}

export interface MinimalTicketResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: MinimalTicket[];
}

export const fetchBatchTickets = async (batchId: number, page: number = 1): Promise<MinimalTicketResponse> => {
  const response = await httpClient.get(`/historical/batches/${batchId}/tickets/?page=${page}`);
  return response.data;
}

/*
request to /api/historical/batches/5/processes/ to get processes for a specific batch:
result:

{
    "count": 1,
    "next": null,
    "previous": null,
    "results": [
        {
            "id": 2,
            "status": "WAITING",
            "result": null,
            "progress": 0.0,
            "created_at": "2023-11-18T12:32:58.136160Z",
            "updated_at": "2023-11-18T12:32:58.136176Z",
            "batch": 5
        }
    ]
}
*/

export interface Process {
  id: number;
  status: string;
  result: string;
  progress: number;
  created_at: string;
  updated_at: string;
  batch: number;
}

export interface ProcessesResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Process[];
}

export const fetchBatchProcesses = async (batchId: number, page: number = 1): Promise<ProcessesResponse> => {
  const response = await httpClient.get(`/historical/batches/${batchId}/processes/?page=${page}`);
  return response.data;
};

export const startBatchProcess = async (batchId: number): Promise<number> => {
  const response = await httpClient.post(`/historical/batches/0/create-process/`, {
    batch: batchId
  });

  return response.data.id || 0;
};

export const createIntegrationConnectionTest = async (integrationType: string, credentials: any): Promise<number> => {
  const response = await httpClient.post(`/itsm/connection-test/`, {
    type: integrationType,
    creds: credentials
  });

  // The ID of the task to be checking on
  return response.data["id"];
};

interface IntegrationConnectionTestResponse {
  id: number;
  type: string;
  status: string;
  result: string;
}

export const fetchIntegrationConnectionTest = async (taskId: number): Promise<IntegrationConnectionTestResponse> => {
  const response = await httpClient.get(`/itsm/connection-test/${taskId}/`);

  return response.data;
};