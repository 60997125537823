import React from "react";
import { useParams } from "react-router";
import {
  Horizontal,
  PageContainer,
  PageContentScroll,
  Panel,
} from "../components/common/Layout";
import { Header, PanelHeader } from "../components/common/Header";
import {
  BatchDetails,
  Process,
  fetchBatch,
  fetchBatchProcesses,
  fetchBatchTickets,
  startBatchProcess,
} from "../utils/backend";
import { useQuery } from "react-query";
import { ListView, SearchParameters } from "../components/common/ListView";
import { KeyValuePair } from "../components/common/KeyValuePair";
import { Link } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const BatchPage: React.FC = () => {
  // batch_id is taken from the URL :batch_id parameter from react-browser-router
  const { batch_id: batchIdParam } = useParams();
  const navigate = useNavigate();

  const batchId = batchIdParam ? parseInt(batchIdParam) : 0;

  // use react-query to fetch batch information using fetchBatch(batchId):
  const {
    data: batch,
    isLoading,
    isError,
  } = useQuery(["batch", batchId], () => fetchBatch(batchId));

  const fetchTickets = async (params: SearchParameters) => {
    const data = await fetchBatchTickets(batchId, params.pageNumber);
    return {
      data: data.results,
      count: data.count,
      rowsPerPage: 100,
    };
  };

  const processBatch = () => {
    startBatchProcess(batchId)
      .then((processId) => {
        navigate(`/batches/process/${processId}`);
      })
      .catch((error) => {
        alert("Error starting batch processing: " + error);
      });
  };

  if (batchId === 0) {
    return <>batch_id not found in URL</>;
  }

  if (isError) {
    return <>Error...</>; // TODO: Show something better here
  }

  if (isLoading || !batch) {
    return <>Loading...</>; // TODO: Show something better here
  }

  return (
    <PageContainer>
      {/*TODO: Finish explanation on specific batch page*/}
      <Header pageName={batch.name} pageDescription="Batches are ..." />{" "}
      <PageContentScroll>
        <Horizontal>
          <Panel height="mid">
            <BatchInfoPanel batch={batch} />
          </Panel>
          <Panel height="mid">
            <PanelHeader text="Tickets in this batch" />
            <ListView
              fetchFunction={fetchTickets}
              columnsToShow={["title", "original_ticket_id"]}
              columnNameOverrides={{
                title: "Ticket Title",
                original_ticket_id: "Ticket ID in ITSM",
              }}
            />
          </Panel>
        </Horizontal>
        <Panel height="wrap">
          <PanelHeader text="Actions" />
          <Box>
            <Button variant="contained" onClick={processBatch}>
              Process batch
            </Button>
          </Box>
        </Panel>
        <Panel height="mid">
          <BatchProcessesPanel batch={batch} />
        </Panel>
      </PageContentScroll>
    </PageContainer>
  );
};

interface BatchInfoPanelParams {
  batch: BatchDetails;
}

const BatchInfoPanel: React.FC<BatchInfoPanelParams> = ({ batch }) => {
  return (
    <>
      <PanelHeader text="Batch Information" />
      <KeyValuePair title="Batch ID" value={batch.id} firstElement />
      <KeyValuePair title="Batch Name" value={batch.name} />
      <KeyValuePair
        title="Batch Source"
        value={batchSourceToString(batch.source)}
      />
      <KeyValuePair
        title="Created At"
        value={new Date(batch.created_at).toLocaleString()}
      />
      <KeyValuePair title="Tickets Count" value={batch.tickets_count} />
    </>
  );
};

const BatchProcessesPanel: React.FC<BatchInfoPanelParams> = ({ batch }) => {
  const fetchProcesses = async (params: SearchParameters) => {
    console.log("Fetching batch processes");
    const response = await fetchBatchProcesses(batch.id, params.pageNumber);

    return {
      data: response.results,
      count: response.count,
      rowsPerPage: 100,
    };
  };

  const renderProcessLink = (process: Process) => {
    return (
      <Link to={`/batches/process/${process.id}`} style={{ color: "inherit" }}>
        {process.id} (click to view)
      </Link>
    );
  };

  return (
    <>
      <PanelHeader text="Active Processing for this batch" />
      <ListView
        fetchFunction={fetchProcesses}
        columnsToShow={["id", "status", "progress", "created_at"]}
        columnNameOverrides={{
          id: "Process ID",
          status: "Status",
          created_at: "Created At",
        }}
        valueRenderOverrides={{
          progress: (process) => Math.round(process.progress * 100) + "%",
          id: renderProcessLink,
        }}
      />
    </>
  );
};

const batchSourceToString = (batchSource: string): string => {
  return (
    {
      JU: "JSON File",
    }[batchSource] || "Unknown"
  );
};
