import styled from "@emotion/styled";
import { Paper } from "@mui/material";

/**
 * PageContainer is a component for using for each page to display content inside the skeleton
 */
export const PageContainer = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});

export const PageContentScroll = styled("div")({
  flex: 1,
  overflowY: "auto",
  display: "block",
  minHeight: 0,

  padding: "0px 32px",

  "& > *": {
    marginBottom: "16px",
  },
});

export const PageContentNoScroll = styled("div")({
  flex: 1,
  overflowY: "hidden",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  padding: "32px",
  paddingTop: 0,
});

export interface PanelProps {
  // Currently "wrap" is only intended to be used outside <Horizontal> components
  height?: "full" | "smol" | "mid" | "wrap";

  children: React.ReactNode;
}

/**
 * TODO: describe this component, maybe add responsive design tricks. For now this just wraps Paper
 */
export const Panel: React.FC<PanelProps> = ({ children, ...props }) => {
  const height = props.height || "full";

  let styleOverrides: any = {
    minHeight: 0,
    padding: "16px 32px",
    display: "flex",
    flexDirection: "column",
  };

  styleOverrides["flex"] = 1;
  if (height === "full") {
    // ...
  } else if (props.height === "smol") {
    styleOverrides["height"] = "30vh";
  } else if (props.height === "mid") {
    styleOverrides["height"] = "50vh";
  } else if (props.height === "wrap") {
    styleOverrides["height"] = "auto";
    styleOverrides["flex"] = "0 1 auto";
  }

  return <Paper sx={styleOverrides}>{children}</Paper>;
};

export const Horizontal = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: "16px",
  // Stretch contents to fill container width:
});
