/**
 * FullPageContainer is a component that is always the size of the screen. It is used to contain the main content
 * in a flex box
 */

import { styled } from "@mui/system";

const FullPageContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,

  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  position: "fixed",
  display: "flex",
  flexDirection: "row",

  // All components that contain other components should have minHeight: 0 *and* this comment above them
  minHeight: 0,
}));

export { FullPageContainer };
