import { Header, PanelHeader } from "../components/common/Header";
import {
  PageContainer,
  PageContentNoScroll,
  Panel,
} from "../components/common/Layout";

import salesforceLogo from "../assets/images/itsm/salesforce-logo.png";
import {
  Box,
  Button,
  CircularProgress,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import { useState } from "react";
import {
  createIntegrationConnectionTest,
  fetchIntegrationConnectionTest,
} from "../utils/backend";

interface ITSMButtonProps {
  name: string;
  logo: string;

  onClick?: () => void;
}

const ITSMButton: React.FC<ITSMButtonProps> = ({ name, logo, onClick }) => (
  <ListItemButton onClick={onClick}>
    <ListItemIcon sx={{ minWidth: 0 }}>
      <img src={logo} alt={name} width={32} />
    </ListItemIcon>
    <ListItemText style={{ marginLeft: "20px" }}>{name}</ListItemText>
  </ListItemButton>
);

interface Field {
  label: string;
  key: string;
  isSecret: boolean;
}

interface ITSMProvider {
  name: string;
  type: string; // The of the ITSM system as represented in the backend
  logo: string;

  credentialFields: Field[];
}

const itsmProviders: ITSMProvider[] = [
  {
    name: "SalesForce",
    type: "SALESFORCE",
    logo: salesforceLogo,
    credentialFields: [
      {
        label: "Username",
        key: "username",
        isSecret: false,
      },
      {
        label: "Password",
        key: "password",
        isSecret: true,
      },
      {
        label: "Security Token",
        key: "security_token",
        isSecret: true,
      },
    ],
  },
];

interface ITSMProviderSelectionProps {
  onSelect: (provider: ITSMProvider) => void;
}

const ITSMProviderSelection: React.FC<ITSMProviderSelectionProps> = ({
  onSelect,
}) => {
  return (
    <>
      <PanelHeader text="Select ITSM software" />
      <Box sx={{ maxWidth: "400px" }}>
        {itsmProviders.map((provider) => (
          <ITSMButton
            key={provider.name}
            name={provider.name}
            logo={provider.logo}
            onClick={() => onSelect(provider)}
          />
        ))}
      </Box>
    </>
  );
};

interface ITSMProviderCredentialsFormProps {
  provider: ITSMProvider;
}

enum CredentialFormState {
  WAITING_FOR_INPUT,
  SENDING_REQUEST,
  WAITING_FOR_VALIDATION,
  VALIDATED,
}

const ITSMProviderCredentialsForm: React.FC<
  ITSMProviderCredentialsFormProps
> = ({ provider }) => {
  const [fieldValues, setFieldValues] = useState<{ [key: string]: string }>({
    password: "TGt1jdLMoM5GGWa",
    username: "itay@tevet.ai",
    security_token: "QvtzxfJ3zaGRxu2VQ3sy87P5S",
  });

  const [state, setState] = useState<CredentialFormState>(
    CredentialFormState.WAITING_FOR_INPUT
  );

  const [result, setResult] = useState<string | null>(null);

  const handleFieldChange = (field: Field, value: string) => {
    setFieldValues((prev) => ({ ...prev, [field.key]: value }));
  };

  const handleSubmit = async () => {
    setState(CredentialFormState.SENDING_REQUEST);

    let requestId = await createIntegrationConnectionTest(
      provider.type,
      fieldValues
    );

    setState(CredentialFormState.WAITING_FOR_VALIDATION);

    let interval = setInterval(async () => {
      let response = await fetchIntegrationConnectionTest(requestId);
      if (response.status === "FINISHED") {
        clearInterval(interval);
        setState(CredentialFormState.VALIDATED);
        setResult(response.result);
      }
    }, 1000);
  };

  const shouldShowLoading =
    state === CredentialFormState.WAITING_FOR_VALIDATION ||
    state === CredentialFormState.SENDING_REQUEST;

  const labelString =
    shouldShowLoading &&
    (state === CredentialFormState.WAITING_FOR_VALIDATION
      ? "Connecting..."
      : "Sending request...");

  return (
    <>
      <PanelHeader text={`Enter credentials for provider ${provider.name}`} />
      <Box sx={{ maxWidth: "50vw" }}>
        {provider.credentialFields.map((field) => (
          <TextField
            key={field.key}
            label={field.label}
            variant="outlined"
            fullWidth
            margin="normal"
            type={field.isSecret ? "password" : undefined}
            onChange={(e) => handleFieldChange(field, e.target.value)}
            value={fieldValues[field.key]}
            disabled={state !== CredentialFormState.WAITING_FOR_INPUT}
          />
        ))}
        {state === CredentialFormState.VALIDATED && (
          <span style={{ fontWeight: "bold", color: "green" }}>
            <br />
            {result}
            <br />
          </span>
        )}
        <Button
          variant="contained"
          onClick={
            state === CredentialFormState.VALIDATED ? undefined : handleSubmit
          }
          disabled={
            state === CredentialFormState.SENDING_REQUEST ||
            state === CredentialFormState.WAITING_FOR_VALIDATION
          }
        >
          {state === CredentialFormState.WAITING_FOR_INPUT
            ? "Connect"
            : state === CredentialFormState.VALIDATED
            ? "Create integration"
            : "Connecting..."}
        </Button>
        <br />
        {shouldShowLoading && <CircularProgress size="small" />}
        <br />
        {labelString}
      </Box>
    </>
  );
};

const ITSMConnectWizard: React.FC = () => {
  const [itsmProvider, setITSMProvider] = useState<ITSMProvider | null>(null);

  const handleProviderSelect = (provider: ITSMProvider) => {
    setITSMProvider(provider);
  };

  return (
    <PageContainer>
      <Header
        pageName="Connect new ITSM system"
        pageDescription="This will Allow Tevet.ai to analyze incoming tickets from this ITSM, as well as import historical tickets to process them"
      />
      <PageContentNoScroll>
        <Panel>
          {itsmProvider == null && (
            <ITSMProviderSelection onSelect={handleProviderSelect} />
          )}

          {itsmProvider != null && (
            <ITSMProviderCredentialsForm provider={itsmProvider} />
          )}
        </Panel>
      </PageContentNoScroll>
    </PageContainer>
  );
};

export { ITSMConnectWizard };
