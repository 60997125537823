/* eslint-disable react-hooks/exhaustive-deps */

import Home from "./Home";
import IssuePage from "./pages/IssuePage";
import IssueListView from "./pages/IssuesListPage";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";

import { theme } from "./style";
import { CssBaseline } from "@mui/material";
import { Skeleton } from "./components/Skeleton";
import { SplashScreen } from "./pages/SplashScreen";
import { IssueCategoryPage } from "./pages/IssueCategoryPage";
import { IssueCategoriesPage } from "./pages/IssueCategoriesPage";
import { LoginScreen } from "./pages/LoginScreen";
import { httpClient } from "./utils/backend";
import { useEffect } from "react";
import { IRootState, store } from "./redux/store";
import { setLoggedIn, setLoggedOut } from "./redux/auth";
import { UploadHistoricalTicketBatchPage } from "./pages/UploadHistoricalTicketBatchPage";
import { BatchesListPage } from "./pages/BatchesListPage";
import { BatchPage } from "./pages/BatchPage";
import { BatchProcessPage } from "./pages/BatchProcessPage";
import { ITSMConnectWizard } from "./pages/ITSMConnectWizard";

const queryClient = new QueryClient();

const FullScreenLoader = () => (
  <div
    style={{
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,

      backgroundColor: "#fff",

      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <img
      src="https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif"
      alt="loading"
      style={{ width: 200 }}
    />
  </div>
);

function LoginController() {
  const { loading, loggedIn } = useSelector((state: IRootState) => state.auth);
  const dispatch = useDispatch();

  console.log("store values: ", loading, loggedIn);

  useEffect(() => {
    httpClient.checkLoggedIn().then((username) => {
      if (username) {
        console.log("user is logged in");
        dispatch(setLoggedIn(username)); // TODO: Get the real login name from checkLoggedIn
      } else {
        console.log("user is logged out");
        dispatch(setLoggedOut());
      }
    });
  }, []);

  if (loading) {
    return <FullScreenLoader />;
  }

  if (!loggedIn) {
    return <LoginScreen />; // TODO: Does this work? idk...
  }

  return (
    <BrowserRouter>
      <CssBaseline />
      <Skeleton>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tickets" element={<IssueListView />} />
          <Route path="/tickets/new" element={<IssuePage />} />

          <Route path="/issue-categories" element={<IssueCategoriesPage />} />
          <Route
            path="/issue-category/:category_id"
            element={<IssueCategoryPage />}
          />

          <Route path="/batches" element={<BatchesListPage />} />
          <Route
            path="/batches/new"
            element={<UploadHistoricalTicketBatchPage />}
          />
          <Route path="/batches/:batch_id" element={<BatchPage />} />
          <Route
            path="/batches/process/:process_id"
            element={<BatchProcessPage />}
          />

          <Route path="/itsm/connect" element={<ITSMConnectWizard />} />

          {/* Setup screens */}
          <Route path="/setup-splash" element={<SplashScreen />} />
        </Routes>
      </Skeleton>
    </BrowserRouter>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <LoginController />
        </QueryClientProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
