import React, { useState, useEffect } from "react";
import {
  Avatar,
  Grid,
  Divider,
  Paper,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import { Spacer } from "../../components/Spacer";
import ScrollBox from "../../components/ScrollBox";
import { fetchIssueCategoryData } from "../../utils/backend"

// Define types for our data
export interface TicketData {
  title: string;
  description: string;
  status: string;
  issueCategory: number;
  comments: Comment[];
}

export interface Comment {
  commenter: string;
  content: string;
}

export interface TicketPageDisplayProps {
  data: TicketData;
  onAddComment?: (text: string) => void;
}

const TicketPageDisplay: React.FC<TicketPageDisplayProps> = ({
  data,
  onAddComment,
}) => {

  const [commentText, setCommentText] = useState("");
  const [issueCategory, setIssueCategory] = useState("");

  // console.log("data tpd: ", data);

  useEffect(() => {
    const loadIssueCategory = async () => {
      if (data.issueCategory !== undefined && data.issueCategory > 0) {
        try {
          // Assuming you have an endpoint to fetch all tickets
          const response = await fetchIssueCategoryData(data.issueCategory);
          console.log("issue category: ", response)
          setIssueCategory(response.label);
        } catch (error) {
          console.error("Failed to fetch issue category", error);
        }
      }
      else {
        setIssueCategory("none");
      }
    };

    loadIssueCategory();
  }, [data]);


  const handleAddComment = () => {
    if (commentText.trim() === "") {
      // Possibly show some notification that the comment shouldn't be empty
      return;
    }

    if (onAddComment) {
      onAddComment(commentText);
    }

    // Clear the comment input field
    setCommentText("");
  };


  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1, minHeight: 0, maxHeight: '100%' }}>
      <IssuePaper>
        <Typography variant="h6">{data.title}</Typography>
        <Typography variant="body1">{data.description}</Typography>
        <Typography variant="body1">{data.status}</Typography>

        {

          (data.status === "CLOSED") &&
          <Typography variant="body1">{issueCategory}</Typography>

        }

      </IssuePaper>

      <CommentsPaper sx={{ minHeight: 0, display: "flex", flex: 1 }}>
        <ScrollBox sx={{ maxHeight: "100%", flex: 1, overflowX: "hidden" }}>
          {data.comments.length ? (
            data.comments.map((comment, index) => (
              <React.Fragment key={index}>
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item>
                    <Avatar alt={comment.commenter} src={undefined} />
                  </Grid>
                  <Grid justifyContent="left" item xs zeroMinWidth>
                    <Typography
                      variant="h6"
                      style={{ margin: 0, textAlign: "left" }}
                    >
                      {comment.commenter}
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: "left" }}>
                      {comment.content}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{ textAlign: "left", color: "gray" }}
                    >
                      {"no timestamp"}
                    </Typography>
                  </Grid>
                </Grid>
                {index < data.comments.length - 1 && (
                  <Divider variant="fullWidth" style={{ margin: "20px 0" }} />
                )}
              </React.Fragment>
            ))
          ) : (
            <Typography variant="body1">
              This ticket does not currently have any comments.
            </Typography>
          )}

          <Spacer height={30} />
          {
            (data.status !== "CLOSED") &&
            (<><TextField
              fullWidth
              variant="outlined"
              label="Add a comment"
              multiline
              rows={4}
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              style={{ marginTop: "20px" }}
            />
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: "10px" }}
                onClick={handleAddComment}
              >
                Add Comment
              </Button></>)
          }
        </ScrollBox>
      </CommentsPaper>
    </div>
  );
};

const IssuePaper = styled(Paper)(({ theme }) => ({
  padding: 16,
  marginBottom: 8,
}));

const CommentsPaper = styled(Paper)(({ theme }) => ({
  padding: "40px 20px",
  marginTop: 0,
}));

export { TicketPageDisplay };
