import React, { useState } from "react";
import { Box } from "@mui/material";
import { Spacer } from "../../components/Spacer";
import { IssueCategory, searchIssueCategories } from "../../utils/backend";
import { Header } from "../../components/common/Header";
import { SearchBar } from "../../components/input/SearchBar";
import { PageContainer, Panel } from "../../components/common/Layout";
import {
  ListView,
  SearchParameters,
  SearchResults,
} from "../../components/common/ListView";

// enum Color {
//   RED = "red",
//   GREEN = "green",
//   BLUE = "blue",
// }

// enum Icon {
//   UP_ARROW = "up_arrow",
//   DOWN_ARROW = "down_arrow",
//   PLAY_BUTTON = "play_button",
// }

// const iconToComponent = (i: Icon) => {
//   // Return material UI Icon component based on icon name
//   switch (i) {
//     case Icon.UP_ARROW:
//       return <ArrowUpward />;
//     case Icon.DOWN_ARROW:
//       return <ArrowDownward />;
//     case Icon.PLAY_BUTTON:
//       return <PlayArrow />;
//   }
// };

// const colorToMaterialColor = (c: Color): string => {
//   // Return material UI color based on color name
//   switch (c) {
//     case Color.RED:
//       return red[300].toString();
//     case Color.GREEN:
//       return green[300].toString();
//     case Color.BLUE:
//       return blue[300].toString();
//     default:
//       return grey[300].toString();
//   }
// };

// interface CategoryTag {
//   text: string;
//   color: Color;
//   icon: Icon;
// }

const IssueCategoriesPage: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");

  // const renderCategoryTags = (tags: CategoryTag[]) => (
  //   <Box sx={{ display: "flex", gap: 1 }}>
  //     {tags.map((tag, index) => (
  //       <Chip
  //         key={index}
  //         label={tag.text}
  //         sx={{ backgroundColor: colorToMaterialColor(tag.color) }}
  //         icon={iconToComponent(tag.icon)}
  //       />
  //     ))}
  //   </Box>
  // );

  return (
    <PageContainer>
      <Header
        pageName="Issue Categories"
        pageDescription="Issue categories are types of issues in your organization, from the user perspective. Every incoming ticket or service inquiry is automatically mapped to one of those, and every issue category has linked troubleshooters to run for incoming tickets."
      />
      <Box sx={{ textAlign: "left" }}>
        <SearchBar
          placeholder="Search for specific issue categories by description, information, or related tickets"
          onChange={setSearchQuery}
        />
      </Box>
      <Spacer height={4} />
      <Panel>
        <ListView
          searchQuery={searchQuery}
          fetchFunction={fetchData}
          columnsToShow={["label", "description", "count"]}
          columnNameOverrides={{
            label: "Issue Category",
            description: "Info",
          }}
        />
      </Panel>
    </PageContainer>
  );
};

const fetchData = async (
  params: SearchParameters
): Promise<SearchResults<IssueCategory>> => {
  const data = await searchIssueCategories(
    params.query || "",
    params.pageNumber
  );
  return {
    data: data.results,
    count: data.count,
    rowsPerPage: 48, // TODO: How do we make this read from backend data??? Can we customize the pagination class for this?
  };
};

export { IssueCategoriesPage };
