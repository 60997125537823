import { Typography } from "@mui/material";
import { RulesList } from "./RulesList";

const mockRules = [
  {
    diagnosis: "Printer is not printing",
    troubleshooter: "Check if printer is on",
  },
  {
    diagnosis: "Printer is not connected to the internet",
    troubleshooter: "Check if printer is on",
  },
  {
    diagnosis: "Printer has never been printing before",
    troubleshooter: "Check if printer is on",
  },
];

const removeDuplications = (list: string[]): string[] => {
  const set = new Set(list);
  return Array.from(set);
};

const RulesListPanel: React.FC = () => {
  return (
    <>
      <Typography variant="body1">
        Rules describe which checks and troubleshooting actions to run for each
        incoming ticket categorized as this specific issue category.
      </Typography>
      <RulesList
        rules={mockRules}
        onRuleChange={() => {}}
        onRuleRemove={() => {}}
        fetchDiagnosisAutoCompleteOptions={() =>
          new Promise((resolve) =>
            resolve(removeDuplications(mockRules.map((rule) => rule.diagnosis)))
          )
        }
        fetchTroubleshooterAutoCompleteOptions={() =>
          new Promise((resolve) =>
            resolve(
              removeDuplications(mockRules.map((rule) => rule.troubleshooter))
            )
          )
        }
      />
    </>
  );
};

export { RulesListPanel };
