import {
  CompareArrows,
  ConfirmationNumberSharp,
  DashboardSharp,
  NoteAddSharp,
  PersonSharp,
  LibraryBooksSharp,
  SettingsSharp,
  AddBox,
} from "@mui/icons-material";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { useLocation, useNavigate } from "react-router";

interface SidebarMenuItemInfo {
  title: string;
  route: string;
  icon: React.ReactNode;
}

interface SidebarMenuGroupInfo {
  title: string;
  items: SidebarMenuItemInfo[];
}

const menuItems: SidebarMenuGroupInfo[] = [
  {
    title: "STATUS",
    items: [
      {
        title: "Active Tickets",
        route: "/active-tickets",
        icon: <ConfirmationNumberSharp />,
      },
      {
        title: "All Tickets",
        route: "/tickets",
        icon: <LibraryBooksSharp />,
      },
      {
        title: "New Ticket",
        route: "/tickets/new",
        icon: <NoteAddSharp />,
      },
    ],
  },
  {
    title: "SETUP (advanced)",
    items: [
      {
        title: "Issue Categories",
        route: "/issue-categories",
        icon: <DashboardSharp />,
      },
      {
        title: "Identities",
        route: "/identities",
        icon: <PersonSharp />,
      },
      {
        title: "Integrations",
        route: "/integrations",
        icon: <CompareArrows />,
      },
    ],
  },
  {
    title: "DATA ANALYSIS",
    items: [
      {
        title: "Batches",
        route: "/batches",
        icon: <LibraryBooksSharp />,
      },
      {
        title: "Upload New Batch",
        route: "/batches/new",
        icon: <AddBox />,
      },
    ],
  },
  {
    title: "MANAGEMENT",
    items: [
      {
        title: "Account",
        route: "/account",
        icon: <SettingsSharp />,
      },
    ],
  },
];

const SidebarIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: theme.spacing(4),
}));

interface SidebarMenuItemProps {
  data: SidebarMenuItemInfo;
}

const SidebarMenuItem: React.FC<SidebarMenuItemProps> = ({ data }) => {
  const path = useLocation();
  const navigate = useNavigate();

  const onClick = () => {
    navigate(data.route);
  };

  return (
    <ListItemButton selected={path.pathname === data.route} onClick={onClick}>
      <SidebarIcon>{data.icon}</SidebarIcon>
      <ListItemText primary={data.title} />
    </ListItemButton>
  );
};

interface SidebarMenuGroupProps {
  data: SidebarMenuGroupInfo;
}

const SidebarMenuGroup: React.FC<SidebarMenuGroupProps> = ({ data }) => {
  return (
    <>
      <ListItem style={{ marginTop: 16 }}>
        <ListItemText
          primary={data.title}
          primaryTypographyProps={{
            color: "textSecondary",
            style: { fontWeight: "bold" },
          }}
        />
      </ListItem>

      {data.items.map((item) => (
        <SidebarMenuItem key={item.title} data={item} />
      ))}
    </>
  );
};

const SidebarContent: React.FC = () => {
  return (
    <SidebarContentContainer>
      {menuItems.map((group, i) => (
        <SidebarMenuGroup data={group} key={i}></SidebarMenuGroup>
      ))}
    </SidebarContentContainer>
  );
};

const SidebarContentContainer = styled("div")(({ theme }) => ({}));

export { SidebarContent };
