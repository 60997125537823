import React, { useState } from "react";
import { styled } from "@mui/system";
import { Paper, Tab, Tabs, Typography } from "@mui/material";
import { Spacer } from "../../components/Spacer";
import { TriggersPanel } from "./TriggersPanel";
import { RulesListPanel } from "./RulesListPanel";

const IssueCategoryPage: React.FC = () => {
  const [tabNumber, setTabNumber] = useState(0);

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabNumber(newValue);
  };

  return (
    <PageContainer>
      <Typography variant="h3">Issue Category Page</Typography>
      <StyledPaper>
        <Tabs value={tabNumber} onChange={onTabChange}>
          <Tab sx={{ width: "150px" }} label="Triggers" />
          <Tab sx={{ width: "150px" }} label="Rules" />
        </Tabs>
        <Spacer height={20} />

        {tabNumber === 0 && <TriggersPanel />}
        {tabNumber === 1 && <RulesListPanel />}
        {/* You can add another conditional rendering block here for tabNumber === 1 to show the "Rules" content */}
      </StyledPaper>
    </PageContainer>
  );
};

const PageContainer = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: "0 24px",
});

const StyledPaper = styled(Paper)({
  flex: 1,
  marginTop: 16,
  padding: "16px 32px",
});

export { IssueCategoryPage };
