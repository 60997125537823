import React from "react";
import { Box } from "@mui/material";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

type ScrollBoxProps = {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

const ScrollBox: React.FC<ScrollBoxProps> = ({ children, sx }) => {
  return (
    <Box
      sx={{
        overflowY: "scroll",
        direction: "rtl", // Makes the scrollbar appear on the left

        // Reset the text direction for children elements
        "& > *": {
          direction: "ltr",
        },

        // Scrollbar styling
        "&::-webkit-scrollbar": {
          width: "8px",
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 5px grey",
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "inset 0 0 5px grey", // Color of the thumb
          borderRadius: "4px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555", // Color of the thumb on hover
        },

        // Merging with user provided styles
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default ScrollBox;
