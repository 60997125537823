import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

interface RuleAutocompleteProps {
  label: string;
  value: string;
  onChange: (newValue: string) => void;
  fetchOptions: (input: string) => Promise<string[]>;
}

const RuleAutocomplete: React.FC<RuleAutocompleteProps> = ({
  label,
  value,
  onChange,
  fetchOptions,
}) => {
  const [options, setOptions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = async (_: any, newInputValue: string | null) => {
    setInputValue(newInputValue || "");
    const newOptions = await fetchOptions(newInputValue || "");
    setOptions(newOptions);
  };

  const handleChange = (_: any, newValue: string | null) => {
    onChange(newValue || "");
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <Autocomplete
      options={options}
      value={inputValue}
      onInputChange={handleInputChange}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" fullWidth />
      )}
      sx={{ flex: 1 }}
    />
  );
};

export default RuleAutocomplete;
