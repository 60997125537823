interface SpacerProps {
  height?: number;
  width?: number;
  full?: boolean;
}

const Spacer: React.FC<SpacerProps> = ({ height, width, full }) => {
  if (height) {
    return <div style={{ display: "block", height }}>&nbsp;</div>;
  }

  if (width) {
    return <div style={{ display: "block", width }}>&nbsp;</div>;
  }

  if (full) {
    return <div style={{ flex: 1 }}>&nbsp;</div>;
  }

  // No spacing...
  return <div>&nbsp;</div>;
};

export { Spacer };
